<template>
    <div class="box">
        <h2>Crefo Prüfungslisten</h2>
        <div class="body">
            <form @submit.prevent="doSearch()" v-show="crefoChecklists.total > 0">
                <div class="form-group form-float with-icon no-error">
                    <input v-model="searchTmp" id="search" placeholder=" " type="text" />
                    <label for="search">Suche</label>
                    <i @click="doSearch()" class="fal fa-search"></i>
                </div>
            </form>
            <table v-show="crefoChecklists.total > 0">
                <thead>
                    <th>Bezeichner</th>
                    <th>Typ</th>
                </thead>
                <tbody>
                    <tr class="clickable-row" v-for="checklist in crefoChecklists.results" v-bind:key="checklist.id">
                        <td @click="load(checklist.id)">{{ checklist.label }}</td>
                        <td v-if="checklist.type == 'freetext'">Freitext</td>
                        <td v-if="checklist.type == 'list'">Liste: {{ checklist.keylist.key }}</td>
                        <td style="width: 2rem">
                            <dropdown class="right">
                                <template slot="trigger"
                                    ><button class="fab btn flat"><i class="fal fa-ellipsis-v"></i></button
                                ></template>
                                <template slot="menu">
                                    <ul>
                                        <li>
                                            <router-link :to="{ name: 'crefo_checklist_edit', params: { id: checklist.id } }"><i class="fal fa-pencil"></i> Edit</router-link>
                                        </li>
                                        <li>
                                            <a href="#" @click.prevent="deleteChecklist(checklist.id)"><i class="fal fa-trash-alt"></i> Delete</a>
                                        </li>
                                    </ul>
                                </template>
                            </dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
            <paging :page="this.page" :page-size="this.pageSize" :total="this.crefoChecklists.total" @select-page="page = $event" />

            <div v-show="crefoChecklists.total == 0" class="info-box">
                Es sind noch keine Prüfungslisten vorhanden
            </div>
        </div>

        <button @click="create()" class="fab size-l br secondary"><i class="fal fa-plus"></i></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchTmp: "",
            page: 1,
            pageSize: 25,
            search: "",
            crefoChecklists: {
                total: 0,
                results: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                q: this.search
            };

            this.$api.get("/crefo/checklists", { params: params }).then(
                response => {
                    this.crefoChecklists = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Listen");
                }
            );
        },
        doSearch() {
            this.search = this.searchTmp;
        },
        create() {
            this.$snotify.confirm("Welche Art von Liste wollen Sie anlegen?", "Listenart", {
                placeholder: "Listenname",
                position: "centerCenter",
                buttons: [
                    {
                        text: "Freitext",
                        action: toast => {
                            this.$snotify.remove(toast.id);
                            this.$router.push({ name: "crefo_checklist_createfreetext" });
                        }
                    },
                    {
                        text: "Listenbasiert",
                        action: toast => {
                            this.$snotify.remove(toast.id);
                            this.$router.push({ name: "crefo_lists", params: { goto: "crefo_checklist_createlist" } });
                        }
                    }
                ]
            });
        },
        load(id) {
            this.$router.push({ name: "crefo_checklist_edit", params: { id: id } });
        },
        deleteChecklist(id) {
            this.$snotify.confirm("Liste dauerhaft löschen?", "Letzte Möglichkeit", {
                closeOnClick: false,
                position: "centerCenter",
                buttons: [
                    {
                        text: "Ja",
                        action: toast => {
                            this.performDelete(id);
                            this.$snotify.remove(toast.id);
                        }
                    },
                    {
                        text: "Nein"
                    }
                ]
            });
        },
        performDelete(id) {
            this.$api.delete("/crefo/checklist/" + id).then(
                () => {
                    // checklist deleted
                    this.$snotify.success("Checkliste gelöscht");
                    this.fetchData();
                },
                () => {
                    // Delete failed
                    this.$snotify.error("Löschen der Checkliste fehlgeschlagen");
                }
            );
        }
    }
};
</script>
